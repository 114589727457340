<!--
 * @Author: wh
 * @Date: 2020-05-25 21:55:00
 * @LastEditTime: 2020-07-15 17:11:52
 * @LastEditors: Please set LastEditors
 * @Description: p2p contacts person
 * @FilePath: \Web\JT.Web\src\views\messageCenter\pages\page\components\dialogue\Contacts.vue
-->
<template>
    <div>
        <ul>
            <li
                class="noData ng-scope"
                v-if="contactsList.length<=0"
            >
                暂无联系人
            </li>
            <li
                v-for="contact in contactsList"
                :key="contact.friend_id"
                @click="enterChat(contact, contact.id)"
                @contextmenu.prevent="openMenu($event, contact)"
                :class="{'active': activeIndex === contact.id}"
            >
                <div class="HeadPortrait">
                    <span class="final_reading" v-if="contact.unReadCount > 0">{{ contact.unReadCount }}</span>
                    <p>{{ contact.name|getLastCharacter }}</p>
                </div>
                <div class="information">
                    <div class="infoTitle">
                        <div class="p_box" :title="contact.name">
                            <p id="name">
                                {{ contact.name }}
                                <span class="time">
                                    {{ contact.updateTimeShow }}
                                </span>
                            </p>
                            <p id="message">
                                {{ contact.lastMsgShow }}
                            </p>
                        </div>
                    </div>
                    <!-- <div class="user-org">
                        <el-tag type="info" :title="contact.friend_org_name">
                            {{ contact.friend_org_name|spliceOrgName }}
                        </el-tag>
                    </div> -->
                </div>
            </li>
        </ul>
        <div v-if="showDropdown" class="menu_box" :style="`position: fixed; left: ${menuLeft}px; top: ${menuTop}px;`">
            <div v-if="DetailData.isPinned === false" @click="menuTopFun(1)">
                置顶
            </div>
            <div v-else @click="menuTopFun(2)">
                取消置顶
            </div>
            <div @click="menuSignFun">
                标为已读
            </div>
            <div @click="menuDelFun">
                删除
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        contactsList: {
            type: [Array],
        },
        tabIndex: {
            type: [Number],
        },
    },
    data() {
        return {
            activeIndex: null,
            menuLeft: 0,
            menuTop: 0,
            showDropdown: false,
            DetailData: {},
        };
    },
    watch: {
        tabIndex() {
            this.activeIndex = null;
            this.DetailData = {};
        },
    },
    created() {
    },
    mounted() {
        // 模拟外部点击
        document.addEventListener('click', e => {
            if (e.target.className !== 'menu_box') {
                this.DetailData = {};
                this.showDropdown = false;
            }
        });
    },
    computed: {
        teamList: function () {
            return window.$globalHub.$store.state.teamlist && window.$globalHub.$store.state.teamlist.filter(team => {
                return team.validToCurrentUser;
            });
        },
    },
    methods: {
        // 发起聊天
        enterChat(rowData, index) {
            this.$emit('clickContact', 'p2p-' + rowData.id, rowData);
            this.activeIndex = index;
        },
        // 打开菜单
        openMenu(e, row) {
            this.DetailData = row;
            this.menuLeft = e.clientX + 10;
            this.menuTop = e.clientY;
            this.showDropdown = true;
        },
        // 置顶
        menuTopFun(flag) {
            const DetailData = this.DetailData;
            this.$WebIM.conn.pinConversation({
                conversationId: this.DetailData.id,
                conversationType: this.DetailData.type,
                isPinned: !this.DetailData.isPinned,
            }).then(() => {
                this.contactsList.forEach((item, i) => {
                    this.$message.success(flag === 1 ? '置顶成功' : '取消置顶成功');
                    if (DetailData.id === item.id) {
                        this.contactsList[i].isPinned = !DetailData.isPinned;
                    }
                });
            }).catch(() => {
                console.log('置顶失败错误');
            });
        },
        // 标为已读
        menuSignFun() {
            this.msgRead();
        },
        // 删除
        menuDelFun() {
            const that = this;
            const options = {
                // 会话 ID：单聊为对方的用户 ID，群聊为群组 ID。
                channel: this.DetailData.id,
                // 会话类型：（默认） `singleChat`：单聊；`groupChat`：群聊。
                chatType: this.DetailData.type,
                // 删除会话时是否同时删除服务端漫游消息。
                deleteRoam: true,
            };
            this.$WebIM.conn.deleteConversation(options).then(() => {
                this.$message.success('删除成功');
                setTimeout(() => {
                    that.$emit('clickContact', null, that.activeIndex === options.channel);
                    if (that.activeIndex === that.DetailData.id) {
                        that.activeIndex = null;
                    }
                }, 500);
            }).catch(e => {
                // 删除失败。
                console.log(e, '删除失败');
            });
        },
        // 已读消息
        msgRead() {
            this.$axios
                .put('/interfaceApi/message/MessageInfo/message_read/' + this.DetailData.id)
                .then(() => {
                    // 
                    this.$message.success('已读成功');
                }).catch(error => {
                    this.$message.error(error.message);
                });
        },
    },
    filters: {
        spliceOrgName(value) {// value是输入框的内容，也是要显示的内容
            if (value) {
                let str = value.toString();
                if (str.length > 6) {
                    str = str.substr(0, 6) + '...';
                }

                return str; // 数组转字符串 以空格输出。。。
            }
            return '';
        },
    },
};
</script>
<style lang="stylus" scoped>
.menu_box
    width 1.3rem
    padding .1rem 0
    background #FFFFFF
    box-shadow 0px 0px 4px 0px rgba(0,0,0,0.3)
    z-index 99
    div
        font-size 14px
        font-family SourceHanSansCN, SourceHanSansCN
        font-weight 400
        color #333333
        width 1.1rem
        text-align left
        height .32rem
        line-height .32rem
        margin 0 .1rem
        padding 0 .1rem
        cursor pointer
        &:hover
            width 1.1rem
            border-radius 2px
            color #fff
            background linear-gradient(90deg, #3C9CFF 0%, #337CFF 100%)
.HeadPortrait
    position relative
    .final_reading
        color #fff
        position absolute
        z-index 1
        display flex
        justify-content center
        align-items center
        right -.05rem
        top .02rem
        min-width .32rem
        height .22rem
        font-size .16rem
        background #F64A2C
        border-radius 8px
        border 1px solid #FFFFFF
    p
        border-radius 2px !important
.information
    padding-top .04rem !important
    .infoTitle
        .p_box
            width 100%
            height 100%
            #name
                font-size .18rem
                padding-right 0.5rem
                position relative
                font-family: SourceHanSansCN, SourceHanSansCN;
                font-weight: 500;
                color: #222222;
                .time
                    position absolute
                    right 0
                    color #666
                    font-size .16rem
            #message
                color #666
                font-size .14rem
            p
                height 50%
</style>
